
.appLauncher .ellipsis {
  position: relative;
}
.appLauncher .ellipsis:before {
  content: '&nbsp;';
  visibility: hidden;
}
.appLauncher .ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}