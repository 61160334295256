table.table-installations>thead>tr>th {
    border-bottom: 1px solid #f0f0f0;
    font-weight: 500;
    position: relative;
    transition: background .3s ease;
    overflow-wrap: break-word;
    z-index:9;
    @apply top-20 s:top-24 xxl:top-28 sticky bg-blueDark text-white
}

table.table-installations>thead>tr>th:before{
    background-color: rgba(0,0,0,.06);
    content: "";
    height: 1.6em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s;
    width: 1px; 
}

table.table-installations>thead>tr>th:last-of-type:before{
    width: 0px; 
}

table.table-installations>tbody>tr>td{
    overflow-wrap: break-word;
    /*padding: 16px;*/
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;    
}

.idTableInstallationsForContract .ant-input-group-addon{ display:none;}

.idTableInstallationsForContract .ant-input-affix-wrapper{
    border-radius: 0.125rem !important;
    @apply border-blueDark !important
}

.idTableInstallationsForContract input,
.idTableInstallationsForContract input::placeholder  {
    color: #102A68 !important;
  }

.idTableInstallationsForContract .ant-pagination-prev .ant-pagination-item-link,
.idTableInstallationsForContract .ant-pagination-next .ant-pagination-item-link{
    border:0px;
    background-color: transparent;
    font-size:0px;
}

.idTableInstallationsForContract .anticon svg{
    height:16px;
    width:16px;
}

.idTableInstallationsForContract .ant-pagination{
    @apply text-base m:text-lg 
}

.idTableInstallationsForContract .ant-pagination-item-active a{
    border-radius: 2px !important;
    @apply bg-blueDark text-white font-normal
}

.idTableInstallationsForContract .ant-pagination-item {
    font-family: acumin-pro!important;
    @apply bg-transparent border-0 min-w-0
}

.idTableInstallationsForContract .ant-pagination-item:hover a{
    @apply text-blueDark
}

.idTableInstallationsForContract .ant-pagination-item-active:hover a{
    @apply text-white
}

.idTableInstallationsForContract .ant-checkbox-inner, .ant-checkbox-input {
    transform: scale(1.5)
}

.idTableInstallationsForContract .ellipsis {
    position: relative;
}

.idTableInstallationsForContract .ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.idTableInstallationsForContract .ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.idTableInstallationsForContract .ant-spin-blur:after{
    opacity:0;
}