.tableShared .ellipsis {
    position: relative;
}

.tableShared .ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.tableShared .ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.paginatorDS .ant-pagination-prev .ant-pagination-item-link,
.paginatorDS .ant-pagination-next .ant-pagination-item-link{
    border:0px;
    background-color: transparent;
    font-size:0px;
}

.paginatorDS .ant-pagination{
    @apply text-base m:text-lg 
}

.paginatorDS .ant-pagination-item-active a{

    border-radius: 2px !important;
    @apply bg-blueDark text-white font-normal
}

.paginatorDS .ant-pagination-item {
    font-family: acumin-pro!important;
    @apply bg-transparent border-0 min-w-0
}


.paginatorDS .ant-pagination-item:hover a{
    @apply text-blueDark
}

.paginatorDS .ant-pagination-item-active:hover a{
    @apply text-white
}

.paginatorDS .anticon svg{
    height:16px;
    width:16px;
}

.tableShared .sticky-documents-salesforce-asset {
    @apply top-16 sticky z-10
}