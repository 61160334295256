
table.table-events>thead>tr>th:before{
    background-color: rgba(0,0,0,.06);
    content: "";
    height: 1.6em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s;
    width: 1px; 
}

table.table-events>thead>tr>th:last-of-type:before{
    width: 0px; 
}

table.table-events>tbody>tr>td{
    overflow-wrap: break-word;
    padding: 16px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;    
}


.idTableEvents .anticon svg{
    height:16px;
    width:16px;
}

.idTableEvents  tbody{
    max-height: 50vh;
}


.idTableEvents .ellipsis {
    position: relative;
}
.idTableEvents .ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}
.idTableEvents .ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}