@tailwind base;
@tailwind components;
@tailwind utilities;


body{ 
  font-family: 'acumin-pro', "Helvetica Neue", sans-serif !important;
  @apply text-blueDark m-0 bg-white
}

h1,h2,h3,h4,h5,h6{
  @apply text-blueDark 
}

h1{ 
 @apply text-lg m:text-xl l:text-2xl xl:text-3xl xxl:text-5xl font-semibold
}

h1.normal{ 
  @apply font-normal 
 }

h2{ 
  @apply  text-xl xxl:text-4xl
 }

h3{
  @apply text-base m:text-lbase xxl:text-xl
}

body.modalOpen {
  @apply overflow-y-hidden
}

div.Content > div,
section.Header > div,
section.welcome > div,
section.Content > div{
  max-width: 1540px;
  @apply m-auto pl-4 pr-4  xxl:pl-0 xxl:pr-0
}
section.Footer > div{
  max-width: 1540px;
  @apply m-auto pl-4 pr-4 xxl:pl-0 xxl:pr-0
}

section{
  @apply bg-white
}

.link{ cursor: pointer;}

.button{
  @apply text-white bg-blueDark rounded-sm pt-2.5 pb-2.5 pl-20 pr-20 justify-between text-xl inline-block
}

.button.disabled{
  @apply text-blueDark bg-disabledButton
}

.button.inactive{
  @apply text-blueDark bg-inActiveButton
}

.ant-switch{
  background-image: linear-gradient(90deg,rgba(0,0,0,.25),rgba(0,0,0,.25)),linear-gradient(90deg,#fff,#fff);
}

.ant-switch-checked {
  background: transparent; /* without "init" aplly isnt working?!*/
  @apply bg-blueLight !important
}

.editableField span:focus{
  outline: none;
}

.ant-tooltip-inner{
  @apply text-blueDark
}

.ant-message-error,
.ant-message-success{
  display: flex;
  justify-items: center;
}

.ant-message-error .anticon,
.ant-message-success .anticon{
  margin-top: 2px;
}

.ant-select-item-option-selected{
  @apply bg-blueBaby !important
}

.ant-pagination .ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon{
  display:contents;
}

.ant-pagination .ant-pagination-jump-next:hover .ant-pagination-item-container,
.ant-pagination .ant-pagination-jump-prev:hover .ant-pagination-item-container{
  margin-top:-2px;
} 

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis{
  margin-top:1px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbars::-webkit-scrollbar {
    display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbars {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

textarea {
  @apply border-blueDark !important
}

.markdocContent{
  @apply pb-24
}

.markdocContent h1{
  @apply mt-6 xl:mt-20 xxl:mt-24 font-bold  text-lg m:text-xl l:text-2xl xl:text-5xl xxl:text-[50px]
}

.markdocContent h2{
  @apply mt-7 xl:mt-16 xxl:mt-20 font-bold text-lbase m:text-lg xl:text-4xl xxl:text-[36px]
}

.markdocContent h3{
  @apply mt-5 xl:mt-16 font-bold text-base m:text-lbase xl:text-2xl xxl:text-[24px]
}

.markdocContent h4,
.markdocContent h5{
  @apply mt-5 xl:mt-16 font-bold text-sm m:text-base xl:text-lbase xxl:text-[20px]
}

.markdocContent p{
  @apply text-sm m:text-base xl:text-l xxl:text-[22px] mt-3 m:mt-6 xl:mt-10
}

.markdocContent table{
  @apply mt-8 xl:mt-12 
}

.markdocContent table tr{
  @apply grid grid-cols-1 m:grid-cols-2 gap-3 m:gap-5
}

.markdocContent .sidebar > div{ @apply sticky xl:top-[280px] xxl:top-[350px]  }
.markdocContent .sidebar a{ @apply block text-[22px] font-normal mb-5 pb-5 border-b border-blueLight2 cursor-pointer}

.scrollMarginTakeCareAboutHeader{
  @apply scroll-m-[150px] xxl:scroll-m-[180px]
}

.markdocContent hr,
.markdocContent ul,
.markdocContent ol {
  @apply mt-3 m:mt-6 xl:mt-10
}

.markdocContent ol ul, 
.markdocContent ul ol{
  @apply mt-1 mb-1 m:mt-3 m:mb-3 xl:mt-5 xl:mb-5
}

.markdocContent ol li,
.markdocContent ul li{
  @apply ml-5 list-disc text-sm m:text-base xl:text-l xxl:text-[22px]
}

.markdocContent ol li{
  @apply list-decimal
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link{
  @apply flex items-center justify-center
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
  @apply text-greyLight text-[22px] font-normal
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-vertical.ant-steps-dot .ant-steps-item-error .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-vertical.ant-steps-dot .ant-steps-item-wait .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot{
  @apply w-4 h-4
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot{
  @apply w-4 h-4
}

.ant-steps-dot .ant-steps-item-tail:after{
  @apply ml-4
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail{
  @apply pt-8 pb-2
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon{
  @apply mr-5 m:mr-10 xl:mr-[52px]
}

.ant-spin-blur:after {
  opacity:0
}

table.table-installations>thead>tr>th {
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  position: relative;
  transition: background .3s ease;
  overflow-wrap: break-word;
  z-index:9;
  @apply top-20 s:top-24 xxl:top-28 sticky bg-blueDark text-white
}

table.table-installations>thead>tr>th:before{
  background-color: rgba(0,0,0,.06);
  content: "";
  height: 1.6em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color .3s;
  width: 1px; 
}

table.table-installations>thead>tr>th:last-of-type:before{
  width: 0px; 
}

table.table-installations>tbody>tr>td{
  overflow-wrap: break-word;
  /*padding: 16px;*/
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;    
}

.idTableContracts .ant-input-group-addon,
.idTableInstallations .ant-input-group-addon{ display:none;}

.idTableContracts .ant-input-affix-wrapper,
.idTableInstallations .ant-input-affix-wrapper{
  border-radius: 0.125rem !important;
  @apply border-blueDark !important
}

.idTableContracts input,
.idTableContracts input::placeholder,
.idTableInstallations input,
.idTableInstallations input::placeholder  {
  color: #102A68 !important;
}

.idTableContracts .ant-pagination-prev .ant-pagination-item-link,
.idTableContracts .ant-pagination-next .ant-pagination-item-link,
.idTableInstallations .ant-pagination-prev .ant-pagination-item-link,
.idTableInstallations .ant-pagination-next .ant-pagination-item-link{
  border:0px;
  background-color: transparent;
  font-size:0px;
}

.idTableContracts .anticon svg,
.idTableInstallations .anticon svg{
  height:16px;
  width:16px;
}

.idTableContracts .ant-pagination,
.idTableInstallations .ant-pagination{
  @apply text-base m:text-lg 
}

.idTableContracts .ant-pagination-item-active a,
.idTableInstallations .ant-pagination-item-active a{
  border-radius: 2px !important;
  @apply bg-blueDark text-white font-normal
}

.idTableContracts .ant-pagination-item ,
.idTableInstallations .ant-pagination-item {
  font-family: acumin-pro!important;
  @apply bg-transparent border-0 min-w-0
}

.idTableContracts .ant-pagination-item:hover a,
.idTableInstallations .ant-pagination-item:hover a{
  @apply text-blueDark
}

.idTableContracts .ant-pagination-item-active:hover a,
.idTableInstallations .ant-pagination-item-active:hover a{
  @apply text-white
}

.idTableContracts .ant-checkbox-inner, .ant-checkbox-input,
.idTableInstallations .ant-checkbox-inner, .ant-checkbox-input {
  transform: scale(1.5)
}

.idTableContracts .ellipsis,
.idTableInstallations .ellipsis {
  position: relative;
}

.idTableContracts .ellipsis:before ,
.idTableInstallations .ellipsis:before {
  content: '&nbsp;';
  visibility: hidden;
}

.idTableContracts .ellipsis span ,
.idTableInstallations .ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.idTableContracts .ant-spin-blur:after,
.idTableInstallations .ant-spin-blur:after{
  opacity:0;
}