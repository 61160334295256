@tailwind utilities;

@layer utilities {

}

section > .Content.bg-blueBaby .ant-spin-blur:after {
    opacity:0.8 !important;
    margin: -2px;
    padding: 19px;
}

section > .Content.bg-blueBaby .ant-spin-container:after{
    background: #C3E6FC !important;
  }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply border-blueDark border
}

.ant-select-arrow{
  @apply text-blueDark
}

.editableField > span{
  outline:none;
}