.table-main .ellipsis {
    position: relative;
}

.table-main .ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.table-main .ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-main .twoLinesEllipses span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.sticky-documents-detail {
    @apply top-16 s:top-16 m:top-24 xxl:top-28 sticky z-10
}

.select-installation > tr{
    @apply h-14
}