
.doorAnimation_south_door,
.doorAnimation_north_south{
    position:relative;
    /*margin-top:-40px;*/
    z-index: 1;
}

/* one door */
.doorAnimation_south_door .doorRight{
    transform: translate(92px, 6px) scale(1.03);
    transition: 1.0s ease;
}

.doorAnimation_south_door.door_south_open .doorRight{
    transform: translate(137px, 2px) scale(0.99);
    transition: 1.0s ease;
}

.doorAnimation_south_door .doorLeft{
    transform: translate( 44px, 11px) scale(0.96);
    transition: 1.0s ease;
}

.doorAnimation_south_door.door_south_open .doorLeft {
    transform: translate( 3px, 15px) scale(1.0);
    transition: 1.0s ease;
}

/* doors north-south */
.doorAnimation_north_south .doorRight{
    transform: translate(92px, 6px) scale(1.03);
    transition: 1.0s ease;
}


.doorAnimation_north_south.door_north_open .doorNorth .doorRight,
.doorAnimation_north_south.door_south_open .doorSouth .doorRight{
    transform: translate(137px, 2px) scale(0.99);
    transition: 1.0s ease;
}

.doorAnimation_north_south .doorLeft{
    transform: translate( 44px, 11px) scale(0.96);
    transition: 1.0s ease;
}

.doorAnimation_north_south.door_north_open .doorNorth .doorLeft,
.doorAnimation_north_south.door_south_open .doorSouth .doorLeft {
    transform: translate( 3px, 15px) scale(1.0);
    transition: 1.0s ease;
}
